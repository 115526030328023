<template>
  <v-row justify="center" class="align-center" no-gutters>
    <v-col cols="6" sm="3" class="text-center">
      <div class="mb-5">正在登入 Azure AD</div>
      <v-progress-linear
        color="primary"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
    </v-col>
  </v-row>
</template>

<script>
import AuthenticationADAL from "@/plugins/AuthenticationADAL";
import { mapActions } from "vuex";

export default {
  async mounted() {
    try {
      await this.setADALData();
      // this.$router.push({ name: "Index" });
    } catch (err) {
      console.error(err);
    }
  },
  methods: {
    ...mapActions({
      setADALData: "user/setADALData",
    }),
  },
};
</script>
